<template>
  <div class="box">
    <div class="mail_left">
      <div class="left_title" :style="`background:${color}`">{{ params.name }}</div>
      <ul class="left_ul">
        <li v-for="(item, index) in dates.witem" :key="index" :class="params.name == item.navigationName ? 'active' : ''" @click="selectItem(index)" :style=" params.name == item.navigationName ? `background:${color}` : ''">
          <span>{{ item.navigationName }}</span>
          <i class="el-icon-arrow-right"></i>
        </li>
      </ul>
    </div>
    <div class="mail_right">
      <div class="rightTitle"></div>
      <a :style="`border-top: 2px ${color} solid`">{{ params.name }}</a>
      <ul class="right_list">
        <li v-for="(item, index) in list" :key="index" @click="goDetails(item.id)">
          <span class="text">·{{ item.journalisTitle }}</span>
          <span class="time">[{{ toDate(item.releaseTime, "yyyy-MM-dd") }}]</span>
        </li>
      </ul>
      <div class="feng"></div>
      <el-pagination class="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.current" :page-size="query.size" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
    </div>
    <div class="feng"></div>
  </div>
</template>
<script>
import {
  selectNavigaionList,
  getNavigationQueryId,
  websiteListGetJournalismId,
  selectList,
} from "@/api/template";
import { mapState } from "vuex";
export default {
  name: "Transfer",
  computed: {
    ...mapState(["color"]),
  },
  data() {
    return {
      params: "",
      query: {
        current: 1,
        navigationId: "",
        navigationType: "",
        size: 15,
      },
      total: 10,
      dates: [],
      details: {},
      leftList: [],
      list: [],
      selectNum: 0,
      selectNumTitle: "",
      isList: false,
      text: "",

      subjectList: [],
    };
  },
  created() {
    // this.params = JSON.parse(window.sessionStorage.getItem("navigationValue"));
    this.params = this.$route.params
    this.init();
    // this.getSelectList();
    this.getSelectNavigaionList();
  },
  methods: {
    init() {
      if (this.params.type == 1) {
        getNavigationQueryId(this.params.id).then((res) => {
          if (this.params.type == 1) {
            if (res.data.witem.length > 0) {
              this.query.navigationId = res.data.witem[0].id;
              this.query.navigationType = res.data.witem[0].navigationType;
              this.getSelectNavigaionList();
            }
            this.dates = res.data ? res.data : [];
            this.list = [];
            this.selectNumTitle =
              this.dates.witem[this.selectNum].navigationName;
          } else {
            this.details = res.data ? res.data : "";
          }
          this.total = res.data.total;
        }).catch(() => {});
      } else {
        websiteListGetJournalismId(this.params.id).then((res) => {
          this.details = res.data;
          this.getTextareaFun(res.data.journalisContent);
        }).catch(() => {});
      }
    },
    getSelectList() {
      let self = this;
      selectList().then((res) => {
        if (res.code == 200) {
          this.subjectList = res.data;
        } else {
          self.$message(res.msg);
        }
      }).catch(() => {});
    },
    getSelectNavigaionList() {
      this.query.navigationId = this.params.id;
      this.query.navigationType = this.params.type;
      selectNavigaionList(this.query).then((res) => {
        if (res.data.records) {
          this.list = res.data.records;
        }
        this.total = res.data.total;
      }).catch(() => {});
    },
    selectItem(index) {
      this.params.name = this.dates.witem[index].navigationName;

      if (this.selectNum == index) {
        return false;
      } else {
        this.params.id = this.dates.witem[index].id;
        this.params.type = this.dates.witem[index].navigationType;
        this.list = [];
        this.getSelectNavigaionList();
      }
      let data = {
        name: this.dates.witem[index].navigationName,
        id: this.dates.witem[index].id,
        type: this.dates.witem[index].navigationType,
      };
      window.sessionStorage.setItem("navigationValue", JSON.stringify(data));
    },
    handleSizeChange(size) {
      this.query.size = size;
      this.getSelectNavigaionList();
    },
    handleCurrentChange(current) {
      this.query.current = current;
      this.getSelectNavigaionList();
    },
    goIndex() {
      this.$router.push("./template-fourth");
      window.sessionStorage.setItem("currentActive", "-100");
    },
    goDetails(id) {
      let data = {
        name: this.params.name,
        id: id,
      };
      window.sessionStorage.setItem("detailsValue", JSON.stringify(data));
      this.$router.push("/fourth-details");
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  width: 980px;
  min-height: 420px;
  margin: 0 auto;
  .mail_left {
    float: left;
    width: 190px;
    background: #f5f5f5;
    min-height: 300px;
    .left_title {
      width: 190px;
      height: 44px;
      font-size: 18px;
      color: #fff;
      line-height: 38px;
      text-align: center;
      margin-bottom: 15px;
      background: url("../images/5397.jpg") no-repeat;
    }
    .left_ul {
      li {
        width: 190px;
        height: 39px;
        line-height: 39px;
        border-bottom: 1px solid #dadada;
        cursor: pointer;
        font-size: 16px;
        color: #444;
        display: flex;
        span {
          display: inline-block;
          width: 144px;
          margin-left: 20px;
          display: -webkit-box;
          overflow: hidden; /*超出隐藏*/
          text-overflow: ellipsis; /*隐藏后添加省略号*/
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1; //想显示多少行
        }
        i {
          line-height: 39px;
        }
      }
      .active {
        background: rgb(97, 175, 241);
        color: #fff;
      }
    }
  }
  .mail_right {
    position: relative;
    float: right;
    width: 760px;
    min-height: 300px;
    .rightTitle {
      height: 40px;
      line-height: 40px;
      background: #f9f9f9;
      border: 1px #d2d2d2 solid;
      margin-bottom: 10px;
    }
    a {
      position: absolute;
      top: 0;
      left: 1px;
      color: #2f2f2f;
      min-width: 108px;
      box-sizing: border-box;
      padding: 0 20px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #fff;
      border-top: 2px #0c87ef solid;
      border-right: 1px #d2d2d2 solid;
      font-size: 16px;
      font-family: Microsoft YaHei;
      display: -webkit-box;
      overflow: hidden; /*超出隐藏*/
      text-overflow: ellipsis; /*隐藏后添加省略号*/
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1; //想显示多少行
    }
    .right_list {
      font-size: 14px;
      line-height: 30px;
      color: #000000;
      min-height: 225px;
      li {
        cursor: pointer;
        list-style-type: none;
        .text {
          width: 650px;
          float: left;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }
        .time {
          float: right;
          width: 110px;
          text-align: right;
          color: #777;
          font-size: 12px;
        }
      }
      li:hover {
        color: #777;
      }
    }
  }
  .feng {
    clear: both;
  }
  .pagination {
    border-top: 1px solid #d2d2d2;
    padding-top: 20px;
    margin-top: 20px;
  }
}
</style>
